import $ from "jquery";
import {BaseResponseObject} from "../Api/Types";
import Deferred = JQuery.Deferred;

type getter_info_t<T extends BaseResponseObject> = {
    items: T[],
    total_items: number
}

export class NavigableTable<RowT extends BaseResponseObject> {
    private readonly next_button: JQuery<HTMLButtonElement>;
    private readonly back_button: JQuery<HTMLButtonElement>;
    private readonly current_page: JQuery;
    private readonly tbody: JQuery;
    private readonly button_wrapper: JQuery;
    private _pages: number;

    private _page: number = 1;
    private _cache: Record<number, getter_info_t<RowT>> = {};
    private readonly getter: (page_index: number) => Deferred<getter_info_t<RowT>>;

    public constructor(
        table_wrapped: JQuery,
        private readonly row_builder: (item: RowT) => JQuery<HTMLTableRowElement>,
        getter: (page_index: number, items_per_page: number) => JQuery.Deferred<getter_info_t<RowT>>,
        private readonly items_per_page: number = 20,
        private readonly cache_size: number = 5
    ) {
        this.tbody = table_wrapped.find("tbody");
        this.next_button = table_wrapped.find<HTMLButtonElement>(".next-btn");
        this.back_button = table_wrapped.find<HTMLButtonElement>(".previous-btn");
        this.current_page = table_wrapped.find<HTMLButtonElement>("[data-content-type='pagenNumber']");
        this.button_wrapper = table_wrapped.find<HTMLButtonElement>("[data-content-type='button_wrapper']");

        this.next_button.on('click', () => {
            this.page++
        });

        this.back_button.on('click', () => {
            this.page--
        });

        this.getter = (page_index) => {
            if (page_index in this._cache)
                return $.Deferred().resolve(this._cache[page_index]);
            return getter(page_index, items_per_page).done(r => {
                const keys = Object.keys(this._cache);
                if (keys.length > this.cache_size)
                    delete this._cache[Number(keys[0])];

                this._cache[page_index] = r;
            });
        }
    }

    public get page_index() {
        return this._page - 1;
    }

    public get page(): number {
        return this._page;
    }

    public get pages(): number {
        return this._pages;
    }

    public set page(page: number) {
        if (page > this.pages)
            page = this._pages - 1;
        else if (page < 1)
            page = 1;

        this._page = page;

        this.current_page.text(page + '/' + this.pages);

        this.update();
    }

    private set pages(pages: number) {
        this._pages = pages;
        this.current_page.text(this.page + '/' + Math.max(1, pages));
        if (this.pages <= 1) {
            this.back_button.hide();
            this.next_button.hide();
            this.button_wrapper.removeClass('navigator').addClass('page-display');
        } else {
            this.back_button.show();
            this.next_button.show();
            this.button_wrapper.removeClass('page-display').addClass('navigator');
        }
    }

    public update() {
        this.next_button.add(this.back_button).prop("disabled", true);

        this.getter(this.page_index).done(x => {
            this.pages = Math.ceil(x.total_items / this.items_per_page);

            this.tbody.empty().append(...x.items.map(i => this.row_builder(i)));
        }).always(() => {
            this.back_button.prop("disabled", this.page === 1);
            this.next_button.prop("disabled", this.page === this.pages);
        })
    }

    public drop_cache() {
        this._cache = {}
    }

    public drop_from_current() {
        for (const k of Object.keys(this._cache).map(Number)) {
            if (k >= this.page_index) {
                delete this._cache[k];
            }
        }
    }

    public drop_current() { // Chiamare su modifica
        delete this._cache[this.page_index];
    }

    public drop_greatest() { // Chiamare quando si aggiunge un record
        delete this._cache[Math.max(...Object.keys(this._cache).map(Number))];
    }
}
