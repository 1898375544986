import $ from "jquery";
import {Popup} from "./Popups/Popup";
import {BaseHandler} from "./Handlers/BaseHandler";
import {LoadDataHandler} from "./Handlers/LoadDataHandler";

import {ManageBatchesHandler} from "./Handlers/ManageBatchesHandler";
import {PrintLettersHandler} from "./Handlers/PrintLettersHandler";
import {ClosePrintingBatchesHandler} from "./Handlers/ClosePrintingBatchesHandler";
import {ExportPaymentsHandler} from "./Handlers/ExportPaymentsHandler";
import {SentMailReportsHandler} from "./Handlers/SentMailReportsHandler";
import {LoginHandler} from "./Handlers/LoginHandler";

export class Smartprise {
    public handler: BaseHandler;

    public RegisterEvents() {
        const url_params = new URLSearchParams(window.location.search);
        //@ts-ignore
        const page = globalThis.page ?? url_params.get("page");

        const handlers: { [page: string]: typeof BaseHandler } = {
            load_data: LoadDataHandler,
            manage_batches: ManageBatchesHandler,
            print_letters: PrintLettersHandler,
            close_printing_batches: ClosePrintingBatchesHandler,
            export_payments: ExportPaymentsHandler,
            sent_mail_reports: SentMailReportsHandler,
            login: LoginHandler
        };

        this.handler = new handlers[page]()
        this.handler.handle();
    }
}


// @ts-ignore
globalThis.smartprise = new Smartprise();
// @ts-ignore
globalThis.Popup = Popup;
// @ts-ignore
globalThis.TS$ = $;

// @ts-ignore
$(() => globalThis.smartprise.RegisterEvents());