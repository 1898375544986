import $ from 'jquery';
import {ManageBatchDetails} from "../Api/Types";
import {Popup} from "../Popups/Popup";
import {BaseHandler} from "./BaseHandler";
import {NavigableTable} from "./NavigableTableHandler";

export class ManageBatchesHandler extends BaseHandler {
    private navigableTable: NavigableTable<ManageBatchDetails>;

    private delete(drop_id: number) {
        this.client.drop_batch(drop_id).ew_done(() => this.get_batches(true));
    }

    private confirm_delete(name: string, drop_id: number): void {
        const title = $(this.templates["popup-title"]).text();
        const description = $(this.templates["popup-description"]);
        description.find('[data-content-type="batch_name"]').text(name);

        const t = Popup.DirectInject(description, title, false, true);
        t.deferred.done(() => this.delete(drop_id))
        t.deferred.always(() => t.popup.Remove());
    }

    private buildRow(e: ManageBatchDetails) {
        const row = $<HTMLTableRowElement>(this.templates["batch-row-template"]);

        row.find('[data-content-type="name"]').text(e.name);
        row.find('[data-content-type="creation_date"]').text(this.format_date(e.creation_date));
        row.find('[data-content-type="items"]').text(e.items);
        row.find('[data-content-type="printed"]').text(e.printed);
        row.find('[data-content-type="paid"]').text(e.paid);
        row.find('[data-content-type="type"]').text(e.type);

        row.find('[data-action="delete"]').on("click", () => this.confirm_delete(e.name, e.drop_id));
        return row;
    }

    private get_batches(dropped: boolean = false) {
        if(dropped) this.navigableTable.drop_from_current();
        else this.navigableTable.drop_cache();
        this.navigableTable.update();
    }

    public override handle() {
        super.handle();
        const tableWrapper = $('#table-wrapper-manage-batches');
        this.navigableTable = new NavigableTable(tableWrapper, e => {
            return this.buildRow(e)
        }, (page_index, items_per_page) => {
            return this.client.get_batches(items_per_page, page_index);
        });
        // this.tableWrapperHandler = new TableWrapperHandler()
        // this.tableWrapperHandler.e.on("check-me", () => {
        //     this.get_batches()
        // });
        this.get_batches();
    }
}
