import $ from 'jquery';
import {BaseHandler} from "./BaseHandler";
import {MailReport} from "../Api/Types";
import {Popup} from "../Popups/Popup";
import {NavigableTable} from "./NavigableTableHandler";

export class SentMailReportsHandler extends BaseHandler {

    private navigableTable: NavigableTable<MailReport>

    private buildRow(e: MailReport):JQuery<HTMLTableRowElement> {
        const table = $('#sent-mail-rep');
        const row = $<HTMLTableRowElement>(this.templates["sent-mail-report-row-template"]);
        row.find('[data-content-type="mail_report_id"]').text(e.mail_report_id);
        row.find('[data-content-type="report_date"]').text(this.format_date(e.report_date));
        row.find('[data-content-type="payments_no"]').text(e.payments_no);

        row.find(e.all_ok ? '[data-when="all_ok"]' : '[data-when="not_all_ok"]').show();

        row.find('[data-action="download"]').on("click", () => this.download(e.zip_download_url))
        row.find('[data-action="details"]').on("click", () => {
            const dom = e.domCacheLookUp(() => {
                const wp = $(this.templates["sent-mail-report-details-template"]);
                const tbody = wp.find("tbody");
                for (const i of e.mail_status) {
                    const row = $(this.templates["sent-mail-report-details-row-template"]);
                    row.find('[data-content-type="name"]').text(i.name);
                    row.find('[data-content-type="mail"]').text(i.mail);
                    row.find('[data-content-type="nation"]').text(i.nation);

                    row.find(i.ok ? '[data-when="ok"]' : '[data-when="not_ok"]').show();

                    tbody.append(row)
                }

                return wp;
            })

            Popup.DirectInject(dom, $(this.templates["details-popup-title"]).text(), true, true).popup.SetShowActions(false);
        })

        table.append(row);
        return row;

    }

    private get_reports(dropped: boolean = false) {
        if (dropped) this.navigableTable.drop_from_current();
        else this.navigableTable.drop_cache();
        this.navigableTable.update();
    }

    public override handle() {
        super.handle();
        const tableWrapper = $('#table-wrapper-sent-mail-reports');
        this.navigableTable = new NavigableTable(tableWrapper, e => {
            return this.buildRow(e)
        }, (page_index, items_per_page) => {
            return this.client.get_mail_reports(items_per_page, page_index);
        });
        this.get_reports();
    }
}