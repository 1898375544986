import {array, custom, JSONObject, optional, required, validate} from 'ts-json-object'


export class BaseResponseObject extends JSONObject {
    public cached_dom: JQuery = null;

    public domCacheLookUp(getter: () => JQuery): JQuery {
        if (this.cached_dom === null)
            return this.cached_dom = getter();
        return this.cached_dom;
    }
}

export class LoginSuccess extends BaseResponseObject {
    @required
    public readonly page: string
}


export class URL extends BaseResponseObject {
    @required
    public readonly url: string;
}

export class EmptyResponse extends BaseResponseObject {
}

export class RetryAfterErrorData extends BaseResponseObject {
    @required
    public readonly retry_after: Date
}

export interface ApiError<T = {}> {
    message: string,
    code: number,
    data: T
}

export interface BaseResponse<ResultT extends BaseResponseObject | BaseResponseObject[] = EmptyResponse,
    ErrT extends ApiError = ApiError> {
    success: boolean,
    error: ErrT,
    result: ResultT
}

export interface GenericPayload {
    [x: string]: unknown;
}

export type RequestOptions = {
    show_loading: boolean,
    default_eh: boolean,
    fail_handle_session_handle: boolean,
    loading_image: string
}

export enum BatchesTypes {
    PRINTABLE,
    WORKED
}

export enum ServerProgressStateType {
    NONE = -1,
    PREPARED = 0,

    UNIQUE_KEY_GENERATION = 11,
    UNIQUE_KEY_GENERATION_DONE = 13,
    UNIQUE_KEY_GENERATION_ERROR = 12,

    PDF_GENERATION = 21,
    PDF_GENERATION_DONE = 23,
    PDF_GENERATION_ERROR = 22,
    PRINT_END_FINISHED = 33,

    CLOSED = 43,

    BATCH_SELECTED = 50,
    SHIPPING_SLIP = 51,
    SHIPPING_SLIP_ERROR = 52,
    SHIPPING_SLIP_DONE = 53,

    SEND_TO_PRINTER = 61,
    SEND_TO_PRINTER_ERROR = 62,
    SEND_TO_PRINTER_DONE = 63,

    PROCESS_CONCLUDED = 70
}

export enum ProgressPhase {
    SELECT_BATCHES_NATIONS = -1,
    SELECT_BATCHES = -1,

    GENERATE_CREDENTIALS = 1,

    GENERATE_PDF = 2,

    SUMMARY = 3,

    SHIPPING_SLIP = 5,

    SEND_TO_PRINTER = 6,
}

export class PaymentDetails extends BaseResponseObject {
    @required
    public readonly payment_id: number;
    @required
    public readonly verbal_id: string;
    @required
    public readonly date: Date;
}

export class ExportPaymentDetails extends BaseResponseObject {
    @required
    @array(PaymentDetails)
    public readonly manual: PaymentDetails[];
    @required
    @array(PaymentDetails)
    public readonly automatic: PaymentDetails[];
}

export class ExportedPayment extends BaseResponseObject {
    @required
    public readonly export_payment_id: number;
    @required
    public readonly export_date: Date;
    @required
    public readonly from_date: Date;
    @required
    public readonly to_date: Date;
    @required
    public readonly automatic_payments_no: number;
    @required
    public readonly manual_payments_no: number;
    @required
    public readonly completed_payments_no: number;
    @required
    public readonly csv_download_url: string;
    @required
    public readonly name: string;

    public get is_aligned(): boolean {
        return this.automatic_payments_no === this.manual_payments_no;
    }

    public get full_items(): number {
        return this.automatic_payments_no + this.manual_payments_no + this.completed_payments_no;
    }
}

export class ExportedPayments extends BaseResponseObject {
    @required
    @array(ExportedPayment)
    public readonly items: ExportedPayment[];

    @required
    public readonly total_items: number;
}

export class MailReportEntry extends BaseResponseObject {
    @required
    public readonly ok: boolean

    @optional
    public readonly name: string

    @optional
    public readonly mail: string

    @required
    public readonly nation: string
}

export class SearchPayments extends BaseResponseObject {
    @required
    public readonly count: number;
}

export class MailReport extends BaseResponseObject {
    @required
    public readonly mail_report_id: number;

    @required
    @array(MailReportEntry)
    public readonly mail_status: MailReportEntry[];

    @required
    public readonly report_date: Date;

    @required
    public readonly payments_no: number;

    @required
    public readonly zip_download_url: string;

    public get all_ok(): boolean {
        return this.mail_status.filter(x => !x.ok).length == 0;
    }
}

export class ExportedPaymentsWrapper extends BaseResponseObject {
    @required
    @array(MailReport)
    public readonly items: MailReport[];

    @required
    public readonly total_items: number;
}


export class PrintError extends BaseResponseObject {
    @required
    public readonly print_work_error_id: number

    @required
    public readonly code: number

    @required
    public readonly description: string

    @required
    public readonly ref_print_work_id: number | null
}

export class Report extends BaseResponseObject {
    @required
    public readonly items: number;

    @required
    public readonly total_import: number;

    @required
    public readonly verbal_no: number;

    @required
    public readonly generated_pdf_no: number;
}

export class FinalReport extends Report {
    @required
    public readonly pdf_download_url: string

    @required
    public readonly distinct_download_url: string

    @required
    public readonly can_reset: boolean;
}

export class FullReport extends Report {
    @array(PrintError)
    @required
    public readonly errors: PrintError[];

    @required
    public readonly closed: boolean;
}

export class EffectiveProgressState {
    public constructor(
        public readonly state: ServerProgressStateType,
        public readonly phase: ProgressPhase,
    ) {
    }
}


export class AsyncTaskStatus extends BaseResponseObject {
    @required
    public readonly running: boolean

    @required
    public readonly phase_concluded: boolean;

    @required
    public readonly any_error: boolean;
}


export class BeginReattach extends AsyncTaskStatus {
    @required
    @custom((_, __, value) => ServerProgressStateType[value.toString().toUpperCase()])
    public readonly progress_state: ServerProgressStateType;

    @required
    public readonly concluded: boolean;

    private cached_effective_state: EffectiveProgressState = null;

    public get effective_state(): EffectiveProgressState {
        if (this.cached_effective_state == null) {
            let state: ProgressPhase;
            const n = Number(typeof this.progress_state === "string" ? ServerProgressStateType[this.progress_state] : this.progress_state) as ServerProgressStateType;

            if (n === ServerProgressStateType.NONE) {
                state = ProgressPhase.SELECT_BATCHES_NATIONS;
            } else if (n === ServerProgressStateType.PREPARED) {
                state = ProgressPhase.GENERATE_CREDENTIALS;
            } else {
                let tmp = n.toString();
                state = Number(tmp.slice(0, tmp.length - 1)) as ProgressPhase;
            }
            this.cached_effective_state = new EffectiveProgressState(n, state);
        }

        return this.cached_effective_state;
    }
}

export class ManageBatchDetails extends BaseResponseObject {
    @required
    public readonly id: number;

    @required
    public readonly name: string;

    @required
    public readonly creation_date: Date;

    @required
    public readonly items: number;

    @required
    public readonly printed: number;

    @required
    public readonly paid: number;

    @required
    public readonly drop_id: number;

    @required
    @custom((_, __, value) => BatchesTypes[value.toString().toUpperCase()])
    public readonly type: BatchesTypes
}

export class ManageBatch extends BaseResponseObject {
    @required
    @array(ManageBatchDetails)
    items: ManageBatchDetails[]

    @required
    public readonly total_items: number;
}


export type GenerateCredentialBatch = {
    customer_id: number;
    nation_ids: number[];
};

export type GenerateCredentialBatches = GenerateCredentialBatch[];

export type ProcessedUpdate = string;
export type InputGetUpdates = ProcessedUpdate[];

export class Update extends BaseResponseObject {
    @required
    public readonly print_id: number;

    @required
    public readonly nation_id: number;

    @required
    public readonly nation_name: string;

    @required
    public readonly processed_no: number;

    @required
    public readonly total_no: number;

    @required
    @custom((_, __, value) => {
        const t = ServerProgressStateType[value.toString().toUpperCase()];
        if (typeof t === "string")
            return ServerProgressStateType[t as keyof typeof ServerProgressStateType];
        return t;
    })
    public readonly progress_state: ServerProgressStateType;

    @required
    public readonly customer_name: string;

    public is_fault(): boolean {
        return this.progress_state.toString().slice(-1) === "2";
    }
}

export class PrintableBatch extends BaseResponseObject {
    @required
    public readonly batch_id: number;

    @required
    public readonly upload_date: Date;

    @required
    public readonly name: string;

    @required
    public readonly items: number;
}

export class PrintableCustomerNation extends BaseResponseObject {
    @required
    public readonly nation: string

    @required
    public readonly nation_id: number

    @required
    public readonly items: number
}

export class PrintableCustomer extends BaseResponseObject {
    @required
    public readonly customer_id: number;

    @required
    public readonly customer_name: string;

    @required
    public readonly items: number;

    @required
    @array(PrintableCustomerNation)
    public readonly nations: PrintableCustomerNation[];

}

export class FailedPrintBatches extends BaseResponseObject {
    @required
    @array(PrintableCustomer)
    public readonly customers: PrintableCustomer[];

    @required
    public readonly batch_ids: number[];
}

export class PrintBatch extends BaseResponseObject {
    @required
    public readonly print_batch_id: number

    @required
    public readonly creation_date: Date

    @optional
    public readonly shipping_date: Date

    @required
    public readonly pending: boolean

    @required
    public readonly name: string

    @required
    public readonly items: number
}

export class PrintBatchWrapper extends BaseResponseObject {
    @required
    @array(PrintBatch)
    public readonly items: PrintBatch[];

    @required
    public readonly total_items: number;
}

export class LoadData extends BaseResponseObject {
    @required
    public readonly rows: number
}

export class UploadProgress extends BaseResponseObject {
    @required
    public readonly total: number;

    @required
    public readonly inserted: number;

    @required
    public readonly hash: string;

    @required
    public readonly name: string;

    @required
    @validate((_, __, v) => ["worked", "printable", "payments"].includes(v))
    public readonly type: "worked" | "printable" | "payments";
}

export class CheckUpload extends BaseResponseObject {
    @required
    public readonly uploading: boolean;
}

export function getDefaultRequestOptions(overwriting: Partial<RequestOptions> = {}): RequestOptions {
    const rt = {
        show_loading: true,
        default_eh: true,
        fail_handle_session_handle: true,
        loading_image: "ui/resources/static/loading.gif"
    }

    Object.assign(rt, overwriting);

    return rt;
}

