import {SmartpriseApiClient} from "../Api/Client";
import $ from "jquery";

export class BaseHandler {
    public meta: JQuery;
    public templates: Record<string, string> = {};

    public static INSTANCE: BaseHandler;

    protected client: SmartpriseApiClient = SmartpriseApiClient.getInstance();
    protected url_params: URLSearchParams;

    protected api_call_loop<RT, ET>(handler: () => JQuery.Deferred<RT, ET>, ms: number) {
        let processing = false;
        return setInterval(() => {
            if (processing)
                return;

            processing = true;

            handler().always(() => processing = false);
        }, ms);
    }

    protected format_date_time(date: Date) {
        if (date === null)
            return "";
        const pad = (num: number | string): string => num.toString().padStart(2, '0');

        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1); // Months are 0-indexed, so we add 1
        const year = date.getFullYear();

        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    protected get_def_name(): string {
        const prefix = $(this.templates["new-key"]).text();
        const suffix = this.format_date_time(new Date());
        return `${prefix} ${suffix}`;
    }

    protected format_date(date: Date): string {
        if (date === null)
            return "";
        return date.toLocaleDateString(undefined, {year: "numeric", month: "2-digit", day: "2-digit"})
    }

    public handle(): void {
        BaseHandler.INSTANCE = this;

        $('[type="month"]').attr('type', this.supportsInputType('month') ? 'month' : 'date')

        for (const element of $(".template").detach().removeClass("template")) {
            const t = $(element);
            this.templates[t.attr("id")] = t.removeAttr("id")[0].outerHTML;
        }

        $("#log-out").on("click", () => {
            this.client.log_out();
        });

        this.meta = $("#meta").detach();
        this.url_params = new URLSearchParams(window.location.search);
    }

    public download(url: string) {
        const link = document.createElement("a");
        link.href = url;
        link.download = url.split("/").slice(-1)[0];
        link.click();
    }

    private supportsInputType(type: string): boolean {
        const input = document.createElement('input');
        input.setAttribute('type', type);
        return input.type === type;
    }

    protected format_date_span(container: JQuery, from: Date, to: Date) {
        return this.format_date(from) + container.attr("data-date-span-sep") + this.format_date(to);
    }
}
